import React from 'react';

import { graphql } from 'gatsby';

import Hero from '../components/Brands/Hero';
import ProductGrid from '../components/Brands/ProductGrid';
import SEO from '../components/SEO/SEO';

export default function BrandsPage({ data }) {
  const brandData = data?.brandData;

  return (
    <>
      <SEO
        title={`Featured Brand - ${brandData?.Title}`}
        description={brandData?.description}
      />
      <Hero brandData={brandData} />
      <ProductGrid brandFilter={brandData?.slug} />
    </>
  );
}

export const query = graphql`
  query featuredBrandQuery($slug: String!) {
    brandData: strapiFeaturedBrands(slug: { eq: $slug }) {
      BrandName
      FeaturedProducts
      LicensedProducer
      Location
      PreviewText
      Title
      id
      slug
      BrandLogo {
        localFile {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
