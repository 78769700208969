/* eslint-disable no-use-before-define */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';

import Axios from 'axios';
import { CheckoutContext } from '../apolloMenu/contexts/checkout';

import ProductCard from '../apolloMenu/productCard/ProductCard';
import mapResultToProduct from '../../utils/mapResultToProduct';
import LocationChanger from './LocationChanger';

const Wrapper = styled.div`
  width: 100%;

  padding: 1% 10%;
  margin-bottom: 50px;

  display: flex;
  flex-direction: column;

  .products {
    margin: 2.5vh 0;
  }

  @media (max-width: 1000px) {
    padding: 1% 5%;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const NoResultsSection = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .product-text {
    font-size: 26px;
  }
`;

export default function ProductGrid({ brandFilter }) {
  const { currentRetailer, retailerList, setCurrentRetailer } =
    useContext(CheckoutContext);

  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    getBrandProducts();
  }, [currentRetailer]);

  function getBrandProducts() {
    setLoading(true);
    // need to use POST as Get via axios does not except body data
    const postUrl = `${process.env.GATSBY_ES_BASE_URL}/api/as/v1/engines/houseofcannabis-es-2/search`;

    const _brandFilter =
      brandFilter[0]?.toUpperCase() + brandFilter.slice(1)?.toLowerCase();
    const postData = {
      query: '',
      filters: {
        all: [{ brand_name: [_brandFilter] }, { store_id: [currentRetailer] }],
      },
    };

    const config = {
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_ES_SECRET_KEY}`,
        'Content-Type': 'application/json',
      },
    };

    Axios.post(postUrl, JSON.stringify(postData), config)
      .then((result) => {
        setProducts(result?.data.results);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const onHandleSelectRetailer = (newRetailerId) => {
    setActiveStore(newRetailerId);
  };

  return (
    <Wrapper>
      <h4 className="products">{products?.length} Products</h4>

      {loading ? (
        <NoResultsSection>
          <h2 className="product-text">Loading Products</h2>
        </NoResultsSection>
      ) : products && products?.length > 0 ? (
        <Grid>
          {products.map((_p, key) => (
            <ProductCard product={mapResultToProduct(_p)} key={key} />
          ))}
        </Grid>
      ) : (
        <NoResultsSection>
          <h2 className="product-text">No Products Found</h2>
          <p>Try Changing Location</p>

          <LocationChanger
            currentRetailer={currentRetailer}
            retailerList={retailerList}
            setCurrentRetailer={setCurrentRetailer}
          />
        </NoResultsSection>
      )}
    </Wrapper>
  );
}
