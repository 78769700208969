import React, { useState } from 'react';
import styled from 'styled-components';

import Img from "gatsby-image"

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 2.5% 0;
`;

const ShowButton = styled.button`
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--darkgreen);
    border: 2px solid var(--darkgreen);
    background: none;

    width: 250px;
    padding: 15px 30px;

    font-family: Oswald;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 17px;

    transition: background-color 250ms;

    .icon{
        position: absolute;
        top: 50%;
        right: 2.5%;

        transform: translateY(-50%);

        height: 35px;
        width: 35px;
    }

    &:hover{
        cursor: pointer;
        color: var(--darkgreen);
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 80%;

    max-height:${({ showmore }) => showmore ? '7500px' : '0px'};

    overflow: hidden;
    
    transition: max-height 1s cubic-bezier(0, 1, 0, 1);

    &.active{
        transition: max-height 1s ease-in-out;
    }

    @media(max-width: 1000px){
        width: 95%;
    }
`;

const DetailBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin: 5vh 0;

    .section{
        order: ${({ isOnLeft }) => isOnLeft ? 0 : 1};

        display: flex;
        flex-direction: column;

        width: 40%;
    }

    .title{
        color: var(--darkgreen);
    }

    .img{
        height: 350px;
        width: 350px;

        border: 1px solid black;
    }

    @media(max-width: 1000px){
        flex-direction: column;

        margin: 2.5vh 0;

        .section{
            width: 95%;
            order: 0;
        }

        .img{
            height: auto;
            width: 95%;
            min-height: 150px;
        }
    }
`;

const BrandImage = styled.img`
    width: min(450px, 45%);
    height: auto;
    border-radius: 5px;

    @media(max-width: 1000px){
        width: 95%;
    }
`;

export default function ShowMore({ sections })
{
    const [showmore, setShowMore] = useState(false);

    return(
        <Wrapper>
            <Details showmore={showmore} className={showmore && 'active'}>
                {sections.map((section, index) => {
                    return <DetailBlock isOnLeft={index % 2 === 0} key={`${section.Title}-${section.id}`}>
                        <div className="section">
                            <h2 className="title">{section.Title}</h2>
                            <p>{section.Description}</p>
                        </div>

                        <BrandImage src={section?.Image?.url}/>
                    </DetailBlock>
                })}
            </Details>

            <ShowButton
                onClick={() => setShowMore(!showmore)}
                //className="hoc-button"
            >
                {showmore ? 'Show Less' : 'Show More'}
                {showmore ? <MdKeyboardArrowUp className="icon"/> : <MdKeyboardArrowDown className="icon"/>}
            </ShowButton>
        </Wrapper>
    )
}