import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import Img from "gatsby-image";

import ShowMore from './ShowMore';

const Wrapper = styled.div`
    width: 100%;

    display: flex;
    justify-content: center;

    margin: 3vh 0;
`;

const BoxWrapper = styled.div`
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 25px;

    background: white;

    .logo{
        height: 100%;
        width: 100%;

        max-height: 200px;
        max-width: 200px;
    }

    .description{
        width: 80%;
        text-align: center;
        white-space: pre-wrap;
    }

    @media(max-width: 1000px){
        width: 95%;

        .description{
            width: 95%;
        }
    }
`;

const Details = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    p{
        font-size: 16px;
    }

    .field{
        opacity: 0.5;
    }

    .main{
        font-weight: 600;
        margin-left: 5px;
    }

    .desktop{
        display: inherit;
    }

    @media(max-width: 1000px){
        p{
            margin: 5px;
        }
    

        .desktop{
            display: none;
        }

        justify-content: center;
    }
`;

export default function Hero({ brandData })
{
    return(
        <Wrapper>
            <BoxWrapper>
                <Img className="logo" fluid={brandData?.BrandLogo[0]?.localFile?.childImageSharp?.fluid}/>

                <Details>
                    <p className="field">BRAND NAME:</p>
                    <p className="main">{brandData.Title}&nbsp;&nbsp;&nbsp;</p>
                    <p className="desktop">|</p>

                    <p className="field">&nbsp;&nbsp;&nbsp;LICENSED PRODUCER:</p>
                    <p className="main">{brandData.LicensedProducer}&nbsp;&nbsp;&nbsp;</p>
                    <p className="desktop">|</p>

                    <p className="field">&nbsp;&nbsp;&nbsp;LOCATION:</p>
                    <p className="main">{brandData.Location}</p>
                </Details>

                <p className="description">
                    {brandData.PreviewText}
                </p>

                <ShowMore sections={brandData.FeaturedProducts}/>
            </BoxWrapper>
        </Wrapper>
    )
}