export default function mapResultToProduct(result) {
  const stringFields = ['id', 'lastupdate', 'name', 'description', 'brand_name', 'category', 
  'subcategory', 'image', 'slug', 'strain_type', 'min_cbd', 'min_thc', 'max_cbd', 'max_thc', 
  'price', 'thc', 'cbd', 'store_id', 'store']
  const arrayFields = ['effects', 'stores', 'weights', 'store_ids', 'store_addys', "stores", "slugs"]
  const jsonFields = ['variants']
  const product = {}


  stringFields.forEach(field => {
    if (result[field] && result[field].raw)
      product[field] = result[field].raw
  } )
  arrayFields.forEach(field => product[field] = (result[field] ? result[field].raw : result[field] = []))

  jsonFields.forEach(field => {
    product[field] = result[field].raw.map(x => JSON.parse(x))
  })
  
  return product
}
