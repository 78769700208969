import React, { useState } from 'react';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
SwiperCore.use([Pagination, Navigation]);

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;

    .desktop{
        display: inherit;
    }

    .mobile{
        display: none;
    }

    .buttonwrapper{
        margin-top: 3vh;
        width: 100%;

        display: flex;
        justify-content: space-between;
    }

    .myswiper{
        width: 100%;
    }

    .hoc-button-outline:disabled, .hoc-button-outline:disabled:hover{
        color: #cccccc;
        border: 3px solid #cccccc;
        background: none;
        padding: 15px 30px;
    }

    @media(max-width: 1000px)
    {
        .desktop{
            display: none;
        }

        .mobile{
            display: inherit;
        }
    }
`;

const LocationCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 2vh 0.5vh;
    border: ${props => props.disabled ? '1px solid #D3D3D3' : '1px solid gray'};
    border-radius: 2vh;
    height: 15vh;
    color: ${props => props.disabled ? '#D3D3D3' : 'auto'};
    &.active{
        border: ${props => props.disabled ? '2px solid #D3D3D3' : '2px solid var(--darkgreen)'};
        color: ${props => props.disabled ? '#D3D3D3' : 'var(--darkgreen)'};
    }
    .top{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: red;
        p{
            margin: 0;
            color: ${props => props.disabled ? '#D3D3D3' : 'black'};
            font-size: max(0.8vw, 12px, 0.8vh);
        }
    }
    h3{
        font-weight: 700;
        font-size: max(1.5vw, 12px, 2vh);
        text-align: center;
        margin: 0;
        color: ${props => props.disabled ? '#9c9c9c' : 'auto'};
    }
    p{
        font-size: max(1vw, 10px, 1.5vh);
        text-align: center;
        margin-bottom: 0;
    }
    &:hover{
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05);
    }
    @media(max-width: 1000px){
        max-width: 35vw;
        padding: 1vh 1vh;
        
        h3{
            font-weight: 700;
            font-size: max(1.75vw, 2.5vh);
            text-align: center;
        }
    }
`

export default function LocationChanger({ currentRetailer, retailerList, setCurrentRetailer })
{
    const [activestore, setActiveStore] = useState(null);

    const onHandleChangeRetailer = () => {
        setCurrentRetailer(activestore);
        setActiveStore(null);
    }

    const onHandleCancel = () => {
        setActiveStore(null);
    }

    return(
        <Wrapper>
            <Swiper
                className="myswiper desktop"
                slidesPerView={4} 
                spaceBetween={25} 
                slidesPerGroup={2} 
                pagination={{
                    "clickable": true
                }} 
                navigation={true}
            >
                {retailerList?.filter((_retailer) => _retailer.id !== currentRetailer)?.map((retailer) => {
                    return(
                        <SwiperSlide key={retailer.id}>
                            <LocationCard 
                                className={activestore === retailer.id ? 'active' : ''}
                                onClick={() => setActiveStore(retailer.id)}
                            >
                                <div className="top">
                                    <h3>{retailer.name.split(" - ")[1]}</h3>
                                    { retailer.distance && <p>{retailer.distance.toFixed(1)}km</p> }
                                </div>
                                <p>
                                    {retailer.address.split(", ").slice(0, 2).join(", ")}
                                </p>
                            </LocationCard>
                        </SwiperSlide>
                    )
                })}
            </Swiper>

            <Swiper
                className="myswiper mobile"
                slidesPerView={2} 
                spaceBetween={5} 
                slidesPerGroup={2} 
                pagination={{
                    "clickable": true
                }} 
                navigation={true}
            >
                {retailerList?.filter((_retailer) => _retailer.id !== currentRetailer)?.map((retailer) => {
                    return(
                        <SwiperSlide key={retailer.id}>
                            <LocationCard 
                                className={activestore === retailer.id ? 'active' : ''}
                                onClick={() => setActiveStore(retailer.id)}
                            >
                                <div className="top">
                                    <h3>{retailer.name.split(" - ")[1]}</h3>
                                    { retailer.distance && <p>{retailer.distance.toFixed(1)}km</p> }
                                </div>
                                <p>
                                    {retailer.address.split(", ").slice(0, 2).join(", ")}
                                </p>
                            </LocationCard>
                        </SwiperSlide>
                    )
                })}
            </Swiper>

            <div className='buttonwrapper'>
                <button 
                    className='hoc-button-outline'
                    disabled={!activestore}
                    onClick={onHandleCancel}
                >
                    Cancel
                </button>

                <button 
                    className='hoc-button-outline'
                    disabled={!activestore}
                    onClick={onHandleChangeRetailer}
                >
                    Change
                </button>
            </div>
        </Wrapper>
    )
}